import * as React from "react"
import Layout from "../components/layout";

const NotFoundPage = () => {
  return (
      <Layout className={'error'} title={'Error'}>
          <section className={'wh-100'}>
              <div className={'container'}>

                  <h1 className={'text-center thin color'}>The page you are calling could not be reached at this time. Please leave a message after the beep.</h1>

              </div>
          </section>

      </Layout>
  )
}

export default NotFoundPage
